import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '../components/atoms/link/link';
import { PageContainer } from '../components/atoms/page-container/page-container';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';

const AboutUsPage = () => {
  const h1Style = 'font-medium text-3xl md:text-4xl mb-10';
  const headshotStyle = 'rounded-full mb-4';
  const nameStyle = 'font-medium mb-1';
  const roleStyle = 'text-sm';

  return (
    <>
      <main>
        <Helmet>
          <title>Hummingbird - About Us</title>
          <meta
            name="description"
            content="We believe technology should help people connect and work together. Despite many automated solutions, personal trainers still know what’s best for their trainees. With easy access to real-time trainee metrics, “personal” in personal training is ready for the next level."
          />
        </Helmet>

        <NavBar />

        <PageContainer>
          <h1 className={h1Style}>About Us</h1>

          <div className="grid lg:grid-cols-2 gap-4">
            <div>
              <p className="mb-8">
                We believe technology should help people connect and work
                together. Especially after Covid-19 changed the world in many
                ways, we wanted to help personal trainers connect and work with
                trainees better.
              </p>

              <p className="mb-8">
                Our solution works for everyone - independent and franchise
                trainers, in-person and virtual sessions, HIIT and
                weightlifting, and so on. It helps overcome situational
                circumstances and provides additional value to trainers’
                business. Without changing their current ways of working,
                trainers can use it however they see fit.
              </p>

              <p className="mb-8">
                Despite many automated solutions, personal trainers still know
                what’s best for their trainees. With easy access to real-time
                trainee metrics, “personal” in personal training is ready for
                the next level.
              </p>

              <p>
                If you have any suggestions, questions, or complaints, please
                don’t hesitate to reach us at{' '}
                <Link
                  to="mailto:support@hummingbird.fit"
                  className="font-bold ml-1"
                >
                  support@hummingbird.fit
                </Link>
                . Otherwise, have a great workout!
              </p>
            </div>

            <div className="flex space-x-20 mt-16 lg:mt-0 lg:justify-center lg:text-center">
              <div>
                <StaticImage
                  src="../images/peter.jpg"
                  alt="Headshot of Peter Ahn"
                  className={headshotStyle}
                  placeholder="blurred"
                  aspectRatio={1}
                  height={151}
                  quality={100}
                />
                <h2 className={nameStyle}>Peter Ahn</h2>
                <p className={roleStyle}>Co-founder</p>
                <Link
                  to="https://www.linkedin.com/in/peterahn/"
                  className={roleStyle}
                >
                  LinkedIn
                </Link>
              </div>

              <div>
                <StaticImage
                  src="../images/rohit.jpg"
                  alt="Headshot of Rohit Rajendran"
                  className={headshotStyle}
                  placeholder="blurred"
                  aspectRatio={1}
                  height={151}
                  quality={100}
                />
                <h2 className={nameStyle}>Rohit Rajendran</h2>
                <p className={roleStyle}>Co-founder</p>
                <Link
                  to="https://www.linkedin.com/in/rrohit/"
                  className={roleStyle}
                >
                  LinkedIn
                </Link>
              </div>
            </div>
          </div>
        </PageContainer>
      </main>

      <Footer />
    </>
  );
};

export default AboutUsPage;
